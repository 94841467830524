<template>
  <v-container fluid class="my-3">
    <v-row>
      <!--Header-->
      <v-col lg="4" md="4" sm="4">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col lg="4" md="4" sm="4" offset-lg="4" offset-md="4" offset-sm="4">
        <!--Total-->
        <p>
          <span style="font-weight: bold">Total:</span>
          <span class="float-right">{{ formatAsCurrency("R", total) }}</span>
        </p>
        <!--Item count-->
        <p>
          <span style="font-weight: bold">Number of items:</span>
          <span class="float-right">{{ itemCount }}</span>
        </p>
      </v-col>
    </v-row>
    <v-divider />
  </v-container>
</template>
<script>
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "TableSummaryAlert",
  props: ["title", "total", "itemCount"],
  data() {
    return {};
  },
  methods: {
    formatAsCurrency,
  },
};
</script>